<template>
  <div class="truck-step3">
    <div class="bg-white flex flex-row">
      <div class="pl-24 pr-6 flex-1">
        <label class=" text-18 text-main font-500">道路运输证<span class="text-danger">(主车)</span></label>
        <div class="truck-card-wrap py-8">
          <tos-uploader
            class="truck-card"
            v-model="transportMainFileList"
            @afterUploaded="afterUploadedTransportCard"
            :max-count="1"
            bgType="daoluyunshuzheng-zhu"
          >
          </tos-uploader>
        </div>
      </div>
      <div class="pl-6 pr-24 flex-1">
        <label class=" text-18 text-main font-500">其他页<span class="text-danger">(主车)</span></label>
        <div class="truck-card-wrap py-8">
          <tos-uploader
            class="truck-card"
            v-model="transportSideFileList"
            :max-count="1"
            bgType="daoluyunshuzheng-zhu"
          />
        </div>
      </div>
    </div>
    <div class="relative pt-8 text-center text-note text-12">上传道路运输证照片，可快速识别相关信息</div>
    <van-form class="px-12" ref="form">
      <div v-for="(item, key) in FormObj" :key="key">
        <van-field
          :class="{ titleError: userVertifiedRef.reasonKeys.includes(key) }"
          required
          :value="userVertifiedRef[key]"
          @input="handleInput(key, $event)"
          v-if="!item.hidden"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="!disabled ? '请输入' : '识别中...'"
          :rules="item.rules"
          @click="onDateClick(key)"
          :disabled="(key === 'roadTransportNumber' || key === 'roadTransportPermitNumber') && numberDisabled"
        >
          <template #label>
            <div :class="{ titleError: userVertifiedRef.reasonKeys.includes(key) }">
              <div>
                {{ item.label }}
              </div>
              <div class="font-12" v-if="userVertifiedRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
        </van-field>
        <div
          v-if="!item.hidden && item.tips"
          class="text-12 text-warning mb-12 p-8 rounded-4"
          style="background: rgba(255, 138, 33, 0.08)"
        >
          <div v-for="(desc, index) in item.tips" :key="index">{{ desc }}</div>
        </div>
      </div>
    </van-form>
    <Popup v-model="showDateRef" position="bottom">
      <DatetimePicker
        title="运输证有效期至"
        type="date"
        :min-date="minDate"
        :maxDate="maxDate"
        v-model="currentDate"
        show-toolbar
        @confirm="handlePopConfirm"
        @cancel="showDateRef = false"
      />
    </Popup>

    <!-- 提示 -->

    <!-- 按钮栏 -->
    <div>
      <div class="truck-btn-box">
        <Button size="large" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed, reactive, onMounted } from '@vue/composition-api';
import { useTruckVerifiedStore } from '@/store/truckVerified.js';
import { getTruckStep3FormData } from '../constants';
import { apiOcrTransportationLicense } from '@api/ocr';
import { Button, Popup, DatetimePicker } from 'vant';
import TosUploader from '@/components/uploader/uploader.vue';
import { buildFormByAuthModeFields } from '@/utils/authMode';
import { useUserStore } from '@/store/user';
import dayjs from 'dayjs';
import { formatToDateTime } from '@wlhy-web-lib/shared';

export default defineComponent({
  name: 'truck-step3',
  components: {
    Button,
    Popup,
    TosUploader,
    DatetimePicker
  },
  setup(_, ctx) {
    const userVerifiedStore = useTruckVerifiedStore();
    const transportMainFileList = ref([]);
    const transportSideFileList = ref([]);
    const disabled = ref(false); // 全局禁用
    const next = ref(true);
    const userStore = useUserStore();
    const currentDate = ref(new Date());
    const maxDate = ref(new Date('2199/12/31'));
    const minDate = ref(new Date('1970/01/02'));
    const totalWeightRef = computed(() => {
      return userVerifiedStore.verifiedInfo.totalWeight;
    });
    const TruckStep3FormData = getTruckStep3FormData(totalWeightRef);
    // 1.3.4 认证模式
    buildFormByAuthModeFields(TruckStep3FormData, userVerifiedStore.authModeFields);
    // =====
    const FormObj = reactive(TruckStep3FormData);

    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);
    const showPop = ref(false);
    const popDetail = reactive({
      title: '',
      content: []
    });
    const numberDisabled = computed(() => {
      return userVerifiedStore.roadDisabled;
    });
    const handleShowTips = (title, content) => {
      popDetail.title = title;
      popDetail.content = content;
      showPop.value = true;
    };
    async function afterUploadedTransportCard(file) {
      // userVerifiedStore.updateTransportImagePass(false);
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrTransportationLicense({ imageUrl: file.url });
        const { data } = res;
        if (data) {
          const { ownerName, licenseNumber, businessCertificate } = data;
          const obj = numberDisabled.value
            ? { businessName: ownerName }
            : {
                businessName: ownerName,
                roadTransportNumber: licenseNumber,
                roadTransportPermitNumber: businessCertificate
              };
          userVerifiedStore.updateInfo(obj);
        }
      } catch (err) {
        // userVerifiedStore.updateTransportImagePass(true);
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    }
    onMounted(() => {
      if (userVerifiedStore?.verifiedInfo?.transportLicenseMainUrl) {
        transportMainFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.transportLicenseMainUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.transportLicenseSideUrl) {
        transportSideFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.transportLicenseSideUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.validEndDate) {
        currentDate.value = new Date(dayjs(userVerifiedStore?.verifiedInfo?.validEndDate).valueOf());
      }
    });

    const handleToNext = async () => {
      // if (userVerifiedStore?.transportImagePass) {
      //   Toast('图片识别未通过，请重新上传');
      //   return;
      // }

      // TODO: 表单验证进数据提交
      const isValid = await validateForm();
      if (!isValid) return;
      userVerifiedStore.updateInfo({
        transportLicenseMainUrl: transportMainFileList?.value[0]?.fileName || '',
        transportLicenseSideUrl: transportSideFileList?.value[0]?.fileName || ''
      });
      userVerifiedStore.setCurrentStep(3);
    };
    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      if (form) {
        try {
          const flag = await form.validate(...params);
          return true;
        } catch (e) {
          console.log(e);
          return false;
        }
      }
      return true;
    };
    const handleInput = (key, val) => {
      let value = val;
      if ((key === 'roadTransportPermitNumber' || key === 'roadTransportNumber') && typeof value === 'string') {
        value = value.toUpperCase();
      }
      userVertifiedRef.value[key] = value;
    };
    const showDateRef = ref(false);
    const onDateClick = key => {
      if (key === 'validEndDate') {
        showDateRef.value = true;
      }
    };
    const handlePopConfirm = e => {
      userVerifiedStore.updateInfo({
        validEndDate: e ? formatToDateTime(e, 'yyyy-MM-dd') : null
      });
      showDateRef.value = false;
      validateForm('validEndDate');
    };
    return {
      FormObj,
      disabled,
      next,
      userVertifiedRef,
      transportMainFileList,
      transportSideFileList,
      afterUploadedTransportCard,
      handleToNext,
      showPop,
      popDetail,
      handleShowTips,
      totalWeightRef,
      handleInput,
      numberDisabled,
      onDateClick,
      showDateRef,
      minDate,
      maxDate,
      currentDate,
      handlePopConfirm
    };
  }
});
</script>
<style lang="less">
.truck-card-wrap {
  // padding: 0.08rem 0.16rem;
  .truck-card {
    width: 100%;
    height: 1rem;
  }
}
</style>
